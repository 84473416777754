import {
  Card,
  CardActions,
  CardContent,
  Button,
  ToggleButtonGroup,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Notiflix from "notiflix";
import HtmlParser from "react-html-parser";
import ReactHtmlParser from "react-html-parser";
import MuiToggleButton from "@mui/material/ToggleButton";
import { useState, useContext } from "react";
import AppContext from "../../../AppContext";

const AnalysisTypeSelector = ({ typeOfAnalysis, setTypeOfAnalysis }) => {
  const ToggleButton = styled(MuiToggleButton)(({ selectedColor }) => ({
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor: selectedColor,
    },
  }));
  const [typeLastSelected, setTypeLastSelected] = useState(typeOfAnalysis);
  const { roles} = useContext(AppContext) 
  const PRICING_INFO = `<div>Aqu&iacute; &uacute;nicamente calcularemos de manera sencilla la cotizaci&oacute;n recomendada para el municipio teniendo en cuenta las caracter&iacute;sticas del mismo. por tanto lo que devolver&aacute; este an&aacute;lisis ser&aacute;:&nbsp;</div>
  <ol>
      <li>
          <div>- Cotizaci&oacute;n recomendada en &euro;</div>
      </li>
      <li>
          <div>- Poblaci&oacute;n del municipio</div>
      </li>
      <li>
          <div>- Superficie, &aacute;rea construida y &aacute;rea industrial</div>
      </li>
      <li>
          <div>- N&uacute;mero de referencias catastrales.</div>
      </li>
  </ol>`;
  const BASIC_INFO = `<div>Estará disponible muy pronto pero aún hay que esperar un poco <br/></strong>Aunque lo denominemos basic, no tiene nada de b&aacute;sico este an&aacute;lisis, pero lo que s&iacute; reporta es un buen overview de la situaci&oacute;n general del municipio en lo que respecta a su parque inmobiliario. Pero, &iquest;qu&eacute; incluye?:&nbsp;</div>
  <div style="margin-left: 20px;"><br><strong>1. Cifras Absolutas:</strong><br>- N&uacute;mero de referencias catastrales totales<br>- N&uacute;mero de metros cuadrados totales del municipio<br>- Poblaci&oacute;n<br><br><strong>2. Cuestiones temporales:<br></strong>- Antig&uuml;edad media<br><br><strong>3. Datos de edificaciones previa prohibici&oacute;n de uso del amianto (Orden ministerial del 7 de diciembre de 2001):<br></strong>- N&uacute;mero de edificios previos o iguales a 2002<br>- Porcentaje de edificios previos a prohibici&oacute;n uso del amianto frente al total<br><br><strong>4. Informaci&oacute;n gr&aacute;fica Gr&aacute;fica:</strong><br>- Gr&aacute;fica de evoluci&oacute;n de la construcci&oacute;n en el municipio<br><br><strong>5. Informaci&oacute;n en forma de Mapas:<br></strong>- Visualizaci&oacute;n de la ubicaci&oacute;n de edificios construidos hasta el 2002 y posteriores</div>`;

  const FULL_INFO = `<div>A&uacute;n no disponible, pero en cuento lo tengamos vas a alucinar con la cantidad informaci&oacute;n que se puede sacar de m&eacute;tricas derivadas de los an&aacute;lisis geoespaciales de Agforest.</div>`;
  const ANALYSIS_TYPES = [
    { value: "PRICING", label: "PRICING", info: PRICING_INFO, needs_full_analysis_permissions: false },
    /*{
      value: "BASIC",
      label: "BASIC ANALYSIS",
      needs_full_analysis_permissions: false,
      info: BASIC_INFO,
    },*/
    { value: "FULL", label: "FULL ANALYSIS", needs_full_analysis_permissions: true, info: FULL_INFO },
  ];
  const handleSelectToogleButtons = (event, type) => {
    setTypeLastSelected(type);
    let type_filtered = ANALYSIS_TYPES.filter((e) => {
      return e.value == type;
    });

    if (
      type_filtered !== null &&
      type_filtered !== undefined &&
      type_filtered.length > 0 &&
      "needs_full_analysis_permissions" in type_filtered[0] &&
      type_filtered[0].needs_full_analysis_permissions
    ) {
      if(roles.includes("ROLE_COMERCIALS_FULL_ANALYSIS")){
       // console.log("Rol comercial encontrado", roles)
      }else{
       // console.log("Rol comercial no encontrado", roles)
        Notiflix.Notify.failure("Este tipo de análisis no está disponible aún");
        event.stopPropagation();
        return;
      }
      
    }
   // console.log("Estableciendo tipo de análisis", type)
    setTypeOfAnalysis(type);

    event.stopPropagation();
    return;
  };
  const GetToggleButton = (value, label, isDisabled = false) => {
    return (
      <ToggleButton
        value={value}
        selectedColor="rgb(var(--color-primary))"
        disabled={isDisabled}
      >
        <label>{label}</label>
      </ToggleButton>
    );
  };

  return (
    <ToggleButtonGroup
      value={typeOfAnalysis}
      exclusive
      onChange={handleSelectToogleButtons}
    >
      {ANALYSIS_TYPES.map((e) => {
        return GetToggleButton(e["value"], e["label"]);
      })}
    </ToggleButtonGroup>
  );
};
export default AnalysisTypeSelector;
