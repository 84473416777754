import React, { useState } from 'react'; // Asegúrate de importar React y useState

function LayerPublicAmianticTextInput({ type = 'text', label, value }) {
  const [_value, setValue] = useState(value);
  const textClickHandler = (e) => {
    console.log("textClickHandler", _value)
    e.stopPropagation();
    e.preventDefault();
   
    if (typeof _value === 'string' && _value.startsWith("http")) {
      console.log("Abriendo link")
      window.open(_value, "_blank", "noreferrer");
    } else {
      console.log("No abriendo link", typeof _value, _value)
    }
  }

  if (typeof _value === 'string' && _value.startsWith("http")) {
    return (
      <div className="input-container" style={{ cursor: "pointer" }} onClick={textClickHandler}>
        <a  style={{ color: "#0000FF",textAlign:"left",  cursor: "pointer", backgroundColor:"white" }} href={_value} target={"_blank"}>Ver</a>
        <label className={_value && 'filled'} style={{ cursor: "pointer" }} onClick={textClickHandler}>
          {label}
        </label>
      </div>
    );
  } else {
    return (
      <div className="input-container">
        <input type={type} value={_value} />
        <label className={_value && 'filled'}>
          {label}
        </label>
      </div>
    );
  }
}

export default LayerPublicAmianticTextInput; // No olvides exportar tu componente
