import { get_graph_data } from "../../services/municipio_service";
import AppContext from "../../../AppContext";
import React, { useContext, useEffect, useState } from "react";
import Notiflix from "notiflix";
import "./Graphics.css"
import {
  ColumnChart,
  DonutChart,
  LineChart,
  PieChart,
  TreemapChart,
} from "../../services/graph_service";
import {Paper} from "@mui/material";

const GraphsForMunicipe = ({ municipe , split_ine=true}) => {
  const [amiantoSusceptibilityCategories, setAmiantoSusceptibilityCategories] =
    useState([]);
  const [pre2002Categories, setPre2002Categories] = useState([]);
  const [buildingAntiqueCategories, setBuildingAntiqueCategories] = useState(
    []
  );
  const [buildingEvolutionCategories, setBuildingEvolutionCategories] =
    useState([]);
  const [currentUseCategories, setCurrentUseCategories] = useState([]);
  const [amiantoSusceptibilityValues, setAmiantoSusceptibilityValues] =
    useState([]);
  const [buildingAntiqueValues, setBuildingAntiqueValues] = useState([]);
  const [buildingEvolutionValues, setBuildingEvolutionValues] = useState([]);
  const [currentUseValues, setCurrentUseValues] = useState([]);
  const [pre2002Values, setPre2002Values] = useState([]);


  const { userHeader, setUserHeader, token, setToken, roles } =
    useContext(AppContext);

  useEffect(() => {
    setAmiantoSusceptibilityCategories([]);
    setBuildingAntiqueCategories([]);
    setBuildingEvolutionCategories([]);
    setCurrentUseCategories([]);
    setPre2002Categories([]);
    setAmiantoSusceptibilityValues([]);
    setBuildingAntiqueValues([]);
    setBuildingEvolutionValues([]);
    setCurrentUseValues([]);
    setPre2002Values([]);
    load_full_analysis_graphics(municipe);
  }, [municipe]);

  function redondearNumeros(arrayNumeros) {
    // Verificar si el primer elemento del array es un número flotante
    if (!Number.isInteger(arrayNumeros[0])) {
      return arrayNumeros.map((numero) => parseFloat(numero.toFixed(2)));
    } else {
      // Si el primer número no es flotante, devuelve el array original
      return arrayNumeros;
    }
  }
  const load_graph_building_antique = async (
    municipio,
    type_of_analysis,
    set_key,
    set_value
  ) => {
    let ine_code = "id" in municipio ? municipio["id"] : false ;
    if(split_ine && ine_code){
      ine_code =  municipio["id"].search("_") ? municipio["id"].split("_")[0]: "id" in municipio? municipio["id"] : false;
    }
    let data = await get_graph_data(ine_code, type_of_analysis, userHeader, token);
    // console.log("Data",type_of_analysis ,data )
    if (data && "keys" in data && "values" in data) {
      const index = data["keys"].indexOf("pre-1900");
      // console.log("Se deben reordenar los elementos? ", index,  data["keys"])
      if (index > -1) {
        // console.log("Reordenando elementos ", data)
        let element = "pre-1900";
        data["keys"].splice(index, 1);
        data["keys"].unshift(element);

        element = data["values"][index];
        data["values"].splice(index, 1);
        data["values"].unshift(element);
        // console.log("elementos reordenados ", data)
      }
      set_key(data["keys"]);
      set_value(redondearNumeros(data["values"]));
    }
    return data;
  };

  const load_current_use_graph = async (
    municipio,
    type_of_analysis,
    set_key,
    set_value
  ) => {
    let ine_code = "id" in municipio ? municipio["id"] : false ;
    if(split_ine && ine_code){
      ine_code =  municipio["id"].search("_") ? municipio["id"].split("_")[0]: "id" in municipio? municipio["id"] : false;
    }
    let data = await get_graph_data(
      ine_code,
      type_of_analysis,
      userHeader,
      token
    );
    if(!data["keys"].includes("Agrícola")){
      data["keys"].unshift("Agrícola")
      data["values"].unshift(0);
    }
    // console.log("Data",type_of_analysis ,data )
    if (data && "keys" in data && "values" in data) {
      set_key(data["keys"]);
      set_value(redondearNumeros(data["values"]));
    }
    return data;
  };
  const load_graph = async (
    municipio,
    type_of_analysis,
    set_key,
    set_value
  ) => {
    let ine_code = "id" in municipio ? municipio["id"] : false ;
    if(split_ine && ine_code){
      ine_code =  municipio["id"].search("_") ? municipio["id"].split("_")[0]: "id" in municipio? municipio["id"] : false;
    }
    let data = await get_graph_data(
      ine_code,
      type_of_analysis,
      userHeader,
      token
    );
    
    // console.log("Data",type_of_analysis ,data )
    if (data && "keys" in data && "values" in data) {
      set_key(data["keys"]);
      set_value(redondearNumeros(data["values"]));
    }
    return data;
  };
  const load_full_analysis_graphics = (municipio) => {
    //Notiflix.Loading.circle();
    const funciones = [
      load_current_use_graph(
        municipio,
        "current_use",
        setCurrentUseCategories,
        setCurrentUseValues
      ),
      load_graph_building_antique(
        municipio,
        "building_evo",
        setBuildingEvolutionCategories,
        setBuildingEvolutionValues
      ),
      load_graph(
        municipio,
        "building_antique",
        setBuildingAntiqueCategories,
        setBuildingAntiqueValues
      ),
      load_graph(
        municipio,
        "amianto_susceptibility",
        setAmiantoSusceptibilityCategories,
        setAmiantoSusceptibilityValues
      ),
      load_graph(
        municipio,
        "pre_2002",
        setPre2002Categories,
        setPre2002Values
      ),
    ];
    Promise.all(funciones)
      .then((results) => {
       // Notiflix.Loading.remove();
      })
      .catch((error) => {
        console.error("Falló la carga del municipio", error);
      });
  };

  return (
    currentUseValues !== undefined &&
    currentUseValues !== false &&
    currentUseValues !== null &&
    currentUseValues != [] && (
      <div className="grid-container">
      <Paper className="grid-item">
          <DonutChart
            categories={amiantoSusceptibilityCategories}
            series={amiantoSusceptibilityValues}
            title={"Susceptibilidad de las edificaciones de contener amianto."}
          />
        </Paper>
        <Paper className="grid-item"
        >
          <ColumnChart
            categories={buildingAntiqueCategories}
            series={buildingAntiqueValues}
            title={"Edad media de los edificios por tipología de uso"}
          />
        </Paper>
        <Paper className="grid-item">

          <LineChart
            categories={buildingEvolutionCategories}
            series={buildingEvolutionValues}
            title={
              "Evolución del desarrollo urbano. Edificaciones por décadas."
            }
          />
        </Paper>
        <Paper className="grid-item">
          <ColumnChart
          isHorizontal={false}
            categories={pre2002Categories}
            series={pre2002Values}
            colors={[
              "#0000FF",
              "#fc8d62"
            ]}
            title={
              "Edificios previos a 2002"
            }
          />
        </Paper>
        <Paper className="grid-item">

          <ColumnChart
            isHorizontal={false}
            colors={[
              "#66c2a5",
              "#fc8d62",
              "#8da0cb",
              "#e78ac3",
              "#a6d854",
              "#ffd92f",
              "#e5c494",
            ]}
            categories={currentUseCategories}
            series={currentUseValues}
            title={"Cantidad de edificaciones por tipología de uso."}
          />
        </Paper>
      </div>
    )
  );
};

export default GraphsForMunicipe;
