import React from 'react';
import Chart from 'react-apexcharts';

function interpolateColorFactor(color1, color2, factor) {
  if (arguments.length < 3) { 
    factor = 0.5; 
  }
  var result = color1.slice();
  for (var i = 0; i < 3; i++) {
    result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
  }
  return result;
};

function interpolateColors(color1, color2, steps) {
  var stepFactor = 1 / (steps - 1),
      interpolatedColorArray = [];

  color1 = color1.match(/\d+/g).map(Number);
  color2 = color2.match(/\d+/g).map(Number);

  for(var i = 0; i < steps; i++) {
    interpolatedColorArray.push(interpolateColorFactor(color1, color2, stepFactor * i));
  }

  return interpolatedColorArray;
}
function rgbToHex(r, g, b) {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}
const createColumnChartHorizontalRoundedOptions = (categories, series, title, additionalOptions, isVertical = false) =>{
  
  let seriesWithColors = series;
  let sortedCategories = categories;
  if(series != false && series.length>0){
    let combined = series.map((value, index) => ({ value, category: categories[index] }));
    combined.sort((a, b) => b.value - a.value);
  
    // Extrae los datos ordenados y sus categorías
    const sortedSeries = combined.map(item => item.value);
    const sortedCategories = combined.map(item => item.category);
    const colorRange = interpolateColors( 'rgb(0, 0, 255)',  'rgb(172, 172, 255)', series.length);
   // console.log("colorRange", colorRange);
    seriesWithColors = sortedSeries.map((value, index) => ({
      x: sortedCategories[index],
      y: value,
     // name : categories[index],
  
      color: colorRange[index],
      //color: rgbToHex(colorRange[index][0], colorRange[index][1], colorRange[index][2]),
    }));
   // console.log("seriesWithColors", seriesWithColors);
  }
 
  return ({
  
  options: {
     tooltip: {
      enabled: true,
      y: {
        formatter: function (value, opts) {
          const sum = opts.series[0].reduce((a, b) => a + b, 0);
          const percent = (value / sum) * 100;
          return value + " ("+percent.toFixed(0) + '%)';
        },
      },
    },
    chart: {
      type: 'bar',
      height:"100%", 
      width:"100%",
      ...additionalOptions,
    },
    theme: {
      monochrome: {
        enabled: true,
        color: '#0000FF',
        shadeTo: 'light',
        shadeIntensity: 0.65
      }
    },
    plotOptions: {
      bar: {
        horizontal: !isVertical,
       distributed:  true, 
        borderRadius: 12,
        dataLabels: {
          position: 'top', // top, center, bottom
        },
      }
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
      offsetY: -2,
      offsetX:40,
      style: {
        fontSize: '12px',
        colors: ["#000000"]
      }
    },
    title: {
      text: title,
      floating: true,
      
      align: 'left',
    },
     xaxis: {
      labels: {
        show: true,
      }
       //categories: sortedCategories,
     },
  },
  series: [{
    name : "Valor",
    data: seriesWithColors,
  }],
  })
};
const createColumnChartVerticalNotRoundedOptions = (categories, series, title, additionalOptions, colors = false) =>{
    // Crear múltiples series, cada una con un solo punto de datos
    if(colors){
      series = series.map((value, index) => ({
        x: categories[index],
        name : categories[index],
        y: value,
        //color: colors[index], // Asignar color a cada serie
      }));

    }
    
  return ({

    options: {
      tooltip: {
        enabled: true,
        y: {
          formatter: function (value, opts) {
            const sum = opts.series[0].reduce((a, b) => a + b, 0);
            const percent = (value / sum) * 100;
            return value + " ("+percent.toFixed(0) + '%)';
          },
        },
      },
      chart: {
        type: 'bar',
        height:"100%", 
        width:"100%",
        ...additionalOptions,
      },

      colors: colors ? colors : [],


      plotOptions: {
        bar: {
          horizontal: false,
          distributed: colors ? true : false, 
          borderRadius: 0,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      
      dataLabels: {
        enabled: false,
        offsetY: 20,
        //offsetX:40,
        style: {
          fontSize: '12px',
          colors: ["#000000"]
        }
      },
      legend: {
        show: false,
      },
      title: {
        text: title,
        floating: true,
        
        align: 'left',
      },
      //  xaxis: {
      //    categories: [],
      //  },
    },
    series: [{
      name : "Valor",
      data: series,
    }],
  });
} 
const createDonutChartOptions = (labels, series, title, additionalOptions) => ({
  options: {
    chart: {
      type: 'donut',
      height:"100%", 
      width:"100%",
      ...additionalOptions,
    },
    labels: labels,
    title: {
      text: title,
    },
    
    colors: ['#fdae61','#1a9641','#a6d96a','#d7191c' ], //['#d7191c', '#fdae61', '#a6d96a','#1a9641' ], // Colores para las categorías
    //         alta      BAJA      media  muy alta   
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
        legend: {

          position: 'bottom',
        },
      },
    }],
  },
  series: series,
});

const createTreemapChartOptions = (data, title, additionalOptions) => {
  return {
  options: {
    chart: {
      height: "100%",
      type: 'treemap',
      ...additionalOptions,
    },
    plotOptions: {
      treemap: {
        enableShades: false,
        // shadeIntensity: 0.2,
        // reverseNegativeShade: true,
        // colorScale: {
        //   ranges: [
        //     {
        //       from: -10,
        //       to: 0,
        //       color: '#0000FF'
        //     },
        //     {
        //       from: 0.001,
        //       to: 10,
        //       color: '#5555FF'
        //     }
        //   ]
        // }
      }
    },
    title: {
      text: title,
    },
  },
}
};

const createLineChartOptions = (categories, series, title, additionalOptions) => ({
  options: {
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      offsetY: -25,
      offsetX: -5
    },
    // fill: {
    //   type: 'gradient',
    //   gradient: {
    //     shade: 'dark',
    //     //gratientFromColors:['#00FF00'], //["#CECEFF"],
    //     gradientToColors: [ '#0000FF'],
    //     shadeIntensity: 1,
    //     type: 'horizontal',
    //     opacityFrom: 1,
    //     opacityTo: 1,
    //     //stops: [0, 100, 100, 100]
    //   },
    // },
    colors : ["#0000FF"],
    chart: {
      height: "100%",
      type: 'line',
      
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
      toolbar: {
        show: false
      },
      ...additionalOptions,
    },
    stroke: {
      curve: 'smooth'
    },
    dataLabels: {
      enabled: false,
      position:"top",
    },
    title: {
      text: title,
    },
    xaxis: {
      categories: categories,
    },
  },
  series: [{
    name: 'Construcciones',
    data: series,
  }],
});

const createPieChartOptions = (labels, series, title, additionalOptions) => ({
  options: {
    chart: {
      type: 'pie',
      ...additionalOptions,
    },
    legend: {
      formatter: function(val, opts) {
        return val + " - " + opts.w.globals.series[opts.seriesIndex]
      }
    },
    theme: {
      monochrome: {
        enabled: true
      }
    },
    labels: labels,
    title: {
      text: title,
    },
    dataLabels: {
      enabled: true,
      position:"top",
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: "100%",
        },
        legend: {
          position: 'bottom',
        },
      },
    }],
  },
  series: series,
});

// Ejemplo de uso de funciones para ColumnChart
const ColumnChart = ({ categories, series, title, additionalOptions, isHorizontal = true, colors = false }) => {
  if(isHorizontal ){
    const { options, series: chartSeries } = createColumnChartHorizontalRoundedOptions(categories, series, title, additionalOptions);
    return <Chart options={options} series={chartSeries} type="bar" width="100%" height="100%"  />;
  }else{
    const { options, series: chartSeries } = createColumnChartVerticalNotRoundedOptions(categories, series, title, additionalOptions, colors);    
    return <Chart options={options} series={chartSeries} type="bar" width="100%" height="100%"  />;
  }

  
};

// Ejemplo de uso de funciones para TreeMapChart
const TreemapChart = ({categories, series , title, additionalOptions }) => {
  if(categories == undefined || categories == null || categories == false){
    return <>Vacío</>
  }
  const data_value = categories.map((label, index) => {
    return { x: label, y: series[index] };
  });
  const data = [{"data":data_value}]
 // console.log("Treemap data", data)
  const { options } = createTreemapChartOptions(data, title, additionalOptions);

  return <Chart options={options} series={data} type="treemap" width="100%"  height="100%"/>;
};

// Ejemplo de uso de funciones para PieChart
const PieChart = ({ categories, series, title, additionalOptions }) => {
  const { options, series: chartSeries } = createPieChartOptions(categories, series, title, additionalOptions);

  return <Chart options={options} series={chartSeries} type="pie" width="100%" height="100%" />;
};

const DonutChart = ({ categories, series, title, additionalOptions }) => {
  const { options, series: chartSeries } = createDonutChartOptions(categories, series, title, additionalOptions);

  return <Chart options={options} series={chartSeries} type="donut" width="100%" height="100%" />;
};
const LineChart = ({ categories, series, title, additionalOptions }) => {
  const { options, series: chartSeries } = createLineChartOptions(categories, series, title, additionalOptions);

  return <Chart options={options} series={chartSeries} type="line" width="100%" height="100%"/>;
};

export { ColumnChart, TreemapChart, PieChart, LineChart, DonutChart };

