import "ol/ol.css";
import React, { useEffect, useRef, useState, createRef } from "react";
import * as ReactDOM from "react-dom";
import Geolocation from "ol/Geolocation.js";
import { Button } from "@mui/material";
import { Fill, Stroke, Text, Style } from "ol/style";
import CircleStyle from "ol/style/Circle";
import LayerGroup from "ol/layer/Group.js";

import "./OpenLayersMap.css";
import {
  LAYER_PUBLIC_AMIANTIC,
  LAYER_REGIONS,
  LAYER_PRE_2002,
  LAYER_DATE_CONSTRUCTION,
  LAYER_CURRENT_USE,
  LAYER_SUSCEPTIBILITY,
  LAYER_TRANSPARENT,
  LAYER_SOLAR,
  LAYER_SOLAR_HEATMAP,
} from "./components/map/Objects/Layers/LayerClass";
import GenericInfoMapModal from "./components/map/GenericInfoMapModal/GenericInfoMapModal";
import { Control } from "ol/control";
import { TextField } from "@mui/material";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import { renderToString } from "react-dom/server";
import { Tile as TileLayer } from "ol/layer";
import FullscreenSharpIcon from "@mui/icons-material/FullscreenSharp";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import Draw from "ol/interaction/Draw.js";
import DrawIcon from "@mui/icons-material/Draw";
import { Vector as VectorSource } from "ol/source";
import GeoJSON from "ol/format/GeoJSON.js";
import RenderFeature, { toFeature } from "ol/render/Feature";
import {
  GeoapifyGeocoderAutocomplete,
  GeoapifyContext,
} from "@geoapify/react-geocoder-autocomplete";
import "@geoapify/geocoder-autocomplete/styles/minimal.css";
import PlaceIcon from "@mui/icons-material/Place";
import { Point } from "ol/geom";
import Notiflix from "notiflix";
import regionsLimits from "./components/map/Objects/regionLimits";
import {
  baseLayerInit,
  calculeCenterOfFeatures,
  getSourceBaseMapFromName,
  handleMapClick,
  addMarkerToMap,
  initProjections,
  mapInit,
  overlayInit,
  readGeoJsons,
  scaleLineInit,
  tooltipInit,
  addGeolocation,
} from "./components/map/commons/geoSpatialFunctions";

const OpenLayersMap = ({
  geojsons = "[]",
  style = { width: "100%", height: "100%", display: "flex" },
  strokeColor = "#CACACA", //"#0000FF",
  strokeHoverColor = "#0000FF",
  strokeWidth = 0.5,
  fillColor = "0, 0, 255",
  fillPolygonsOpacity = "0.85",
  fillPointsOpacity = "0.8",
  defaultSelectedLayer = "publicAmiantic",
  layers_data = false,
  getBgColorFromFeature = (feature) => {
    return "116, 86, 171";
  },
  mustShowOnClickFeature = true,
  isDrawEnabled = false,
  isDrawEnabledOnStartUp = false,
  assetsBasePath = false,
  isAdressSearchBarEnabled = true,
  isGetCoordinatesOnClickEnabled = false,
  isGeolocationEnabled = false,
  layersSelected = "amianticPublic", //"regionLimits", //"amianticPublic", // "susceptibility", "regionLimits"
  ineCode = null,
  centerLocationControl = true,
  show_ui = true,
  center_on_new_polygons = false, //a true
}) => {
  const [selectedLayer, setSelectedLayer] = useState(defaultSelectedLayer);
  const [drawEnabled, setDrawEnabled] = useState(false);
  const [showUI, setShowUI] = useState(show_ui);
  const GEOAPIFY_KEY = "4eb2e90e60de455bb2360adb3c89c15b";
  const geoapify_ref = createRef(null);

  const [have_geometry_not_empty_once, set_have_geometry_not_empty_once] =
    useState(0);
  let preloadCenter = false;
  let preloadZoom = false;

  var pointMarker = [false];
  let DEFAULT_CENTER = [-3.70256, 40.4165]; // default madrid
  let drawSource = new VectorSource({ wrapX: false });
  let draw;
  let mustClean = false;
  const drawType = "Polygon";
  draw = new Draw({
    source: drawSource,
    type: drawType,
  });
  const [oldZoom, setOldZoom] = useState(false);
  const [oldCenter, setOldCenter] = useState(false);
  const [layerHasChanged, setLayerHasChanged] = useState(false);
  //let layerHasChanged = false;
  //let oldZoom = false;
  //let oldCenter = false;
  let DEFAULT_LAYERS_DATA = {};

  const set_default_layers = () => {
    if (layersSelected == "amianticPublic") {
      DEFAULT_LAYERS_DATA = {
        // "pre2002":LAYER_PRE_2002,
        // "dateConstruction":LAYER_DATE_CONSTRUCTION,
        //"solar":LAYER_SOLAR,
        //"solar_heatmap":LAYER_SOLAR_HEATMAP
        // "amiantoSusceptibility" : LAYER_SUSCEPTIBILITY,
        // // "currentUse" : LAYER_CURRENT_USE,
        publicAmiantic: LAYER_PUBLIC_AMIANTIC,
        transparent: LAYER_TRANSPARENT,
      };
      if (!Object.keys(DEFAULT_LAYERS_DATA).includes(selectedLayer)) {
        setSelectedLayer("publicAmiantic");
      }
    } else if (layersSelected == "regionLimits") {
      DEFAULT_LAYERS_DATA = {
        // "pre2002":LAYER_PRE_2002,
        // "dateConstruction":LAYER_DATE_CONSTRUCTION,
        //"solar":LAYER_SOLAR,
        //"solar_heatmap":LAYER_SOLAR_HEATMAP
        // "amiantoSusceptibility" : LAYER_SUSCEPTIBILITY,
        // // "currentUse" : LAYER_CURRENT_USE,
        // "publicAmiantic":LAYER_PUBLIC_AMIANTIC,
        regionLimits: LAYER_REGIONS,
        transparent: LAYER_TRANSPARENT,
      };
      if (!Object.keys(DEFAULT_LAYERS_DATA).includes(selectedLayer)) {
        setSelectedLayer("regionLimits");
      }
    } else if (layersSelected == "solar") {
      DEFAULT_LAYERS_DATA = {
        // "pre2002":LAYER_PRE_2002,
        // "dateConstruction":LAYER_DATE_CONSTRUCTION,
        solar: LAYER_SOLAR,
        solar_heatmap: LAYER_SOLAR_HEATMAP,
        // "amiantoSusceptibility" : LAYER_SUSCEPTIBILITY,
        // // "currentUse" : LAYER_CURRENT_USE,
        // "publicAmiantic":LAYER_PUBLIC_AMIANTIC,
        // "regionLimits":LAYER_REGIONS,
        transparent: LAYER_TRANSPARENT,
      };
      if (!Object.keys(DEFAULT_LAYERS_DATA).includes(selectedLayer)) {
        setSelectedLayer("solar_heatmap");
      }
    } else if (layersSelected == "susceptibility") {
      DEFAULT_LAYERS_DATA = {
        // "pre2002":LAYER_PRE_2002,
        // "dateConstruction":LAYER_DATE_CONSTRUCTION,
        //"solar":LAYER_SOLAR,
        //"solar_heatmap":LAYER_SOLAR_HEATMAP
        amiantoSusceptibility: LAYER_SUSCEPTIBILITY,
        // // "currentUse" : LAYER_CURRENT_USE,
        // "publicAmiantic":LAYER_PUBLIC_AMIANTIC,
        transparent: LAYER_TRANSPARENT,
      };
      if (!Object.keys(DEFAULT_LAYERS_DATA).includes(selectedLayer)) {
        setSelectedLayer("amiantoSusceptibility");
      }
    } else if (layersSelected == "full") {
      DEFAULT_LAYERS_DATA = {
        pre2002: LAYER_PRE_2002,
        dateConstruction: LAYER_DATE_CONSTRUCTION,
        //"solar":LAYER_SOLAR,
        //"solar_heatmap":LAYER_SOLAR_HEATMAP
        amiantoSusceptibility: LAYER_SUSCEPTIBILITY,
        currentUse: LAYER_CURRENT_USE,
        // "publicAmiantic":LAYER_PUBLIC_AMIANTIC,
        transparent: LAYER_TRANSPARENT,
      };
      if (!Object.keys(DEFAULT_LAYERS_DATA).includes(selectedLayer)) {
        setSelectedLayer("amiantoSusceptibility");
      }
    }
    layers_data = DEFAULT_LAYERS_DATA;
  };
  set_default_layers();

  const [geoPositionPoint, setGeoPositionPoint] = useState();
  const [geoAccuraccyGeometry, setGeoAccuraccyGeometry] = useState(null);
  layers_data = layers_data != false ? layers_data : DEFAULT_LAYERS_DATA;
  getBgColorFromFeature = (feature) => {
    return layers_data[selectedLayer].colorBgFeatureFunction(feature);
  };
  // useEffect(()=>{
  //   set_default_layers();

  // }, [layersSelected])
  const mapRef = useRef();
  const tooltipRef = useRef();
  const mapContainerRef = useRef();
  const fullScreenButtonRef = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalBody, setModalBody] = useState(<></>);
  let mustConfigureGeolocation = true;
  let geolocationVectorLayer = false;
  let geolocation = new Geolocation({
    // enableHighAccuracy must be set to true to have the heading value.
    trackingOptions: {
      enableHighAccuracy: true,
    },
    // projection: map.getView().getProjection(),
  });
  useEffect(() => {
    if (isGeolocationEnabled) {
      geolocation.setTracking(true);
      mustConfigureGeolocation = true;
      // geolocation.once('change:position', async function () {

      //   let coordinates = geolocation.getPosition();
      //   // if (coordinates) {
      //   //   console.log("Actualizando coordenadas", coordinates)
      //   //   setGeoPositionPoint(coordinates);
      //   //   positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);
      //   // }
      //   if (map){
      //     map.getView().animate({
      //       center: coordinates,
      //       zoom: 17,
      //       duration: 350 // Duración de la animación en milisegundos, ajusta según necesites
      //     });
      //   }

      // })
      const c = geolocation.getPosition();
      console.log("Coordinates", c);
      if (c) {
        setGeoPositionPoint(c);
      }
      if (map && c) {
        map.getView().animate({
          center: c,
          zoom: 17,
          duration: 350, // Duración de la animación en milisegundos, ajusta según necesites
        });
      } else {
        geolocation.once("change:position", async function () {
          let coordinates = geolocation.getPosition();
          console.log("Coordinates", coordinates);
          setGeoPositionPoint(coordinates);
          map.getView().animate({
            center: coordinates,
            zoom: 17,
            duration: 350, // Duración de la animación en milisegundos, ajusta según necesites
          });
        });
      }
    }
  }, [isGeolocationEnabled]);

  //const overlayRef = useRef();
  const popupRef = useRef(null);
  const [coordinate, setCoordinate] = useState(false);
  let overlay = overlayInit(popupRef);
  const scaleLineControl = scaleLineInit();

  const DEFAULT_PROJECTION = "EPSG:4326";
  let combinedCenter = DEFAULT_CENTER; // default madrid
  let combinedExtent = false;
  const BASE_MAPS_AVIABLES = ["PNOAWMTS", "CATASTRO", "osm"];
  const BASE_MAPS_IMAGES_PATHS = [
    "map_satellite.jpg",
    "catastro.jpeg",
    "map_street.jpg",
  ];
  let base_maps_index = 0;
  //const [defaultBaseMap, setDefaultBaseMap] = useState(BASE_MAPS_AVIABLES[base_maps_index]);

  const baseLayer = baseLayerInit(BASE_MAPS_AVIABLES[base_maps_index]);
  let map = null;
  const [mapState, setMapState] = useState(null);
  const [highlightedFeature, setHighlightedFeature] = useState(null);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const SearchAddressDiv = document.createElement("div");
  SearchAddressDiv.className = "geoapify-autocomplete-input-box";
  const onPlaceSelect = (place) => {
    console.log("onPlaceSelect : ", place);
    const lon = place?.properties?.lon;
    const lat = place?.properties?.lat;
    const street = place?.properties?.formatted;
    console.log("Coords", lat, lon, street);

    // map.getView().setCenter([lat,lon]);
    // map.getView().setZoom(17);

    map.getView().animate({
      center: [lon, lat],
      zoom: 17,
      duration: 350, // Duración de la animación en milisegundos, ajusta según necesites
    });
    console.log("Enviando mapclick event barra busqueda", [lon, lat]);
    var evt = new CustomEvent("mapClick", {
      detail: { coordinates: [lon, lat], location: street },
    });
    document.dispatchEvent(evt);
    addMarkerToMap(map, [lon, lat], pointMarker, assetsBasePath);
  };
  // function sendGeocoderRequest(value, geocoder) { // TODO
  //   console.log(value); //the search term
  //   return geocoder.sendGeocoderRequest(value);

  // }
  //const root = createRoot(SearchAddressDiv);
  //root.render(
  ReactDOM.render(
    <GeoapifyContext ref={geoapify_ref} apiKey={GEOAPIFY_KEY}>
      <GeoapifyGeocoderAutocomplete
        placeholder="Escriba una dirección aquí."
        //filterByCountryCode={["es"]}
        lang={"es"}
        //type={"street"}
        //debounceDelay={200}

        addDetails={false}
        placeSelect={onPlaceSelect}
      />
    </GeoapifyContext>,
    SearchAddressDiv
  );
  class LeyendControl extends Control {
    constructor() {
      let options = {};
      let leyend = document.createElement("div");
      const l = layers_data[selectedLayer].legend();
      let element = document.createElement("div");
      if (l != false) {
        leyend.innerHTML = renderToString(l);

        element.className = "leyend-map ol-unselectable ol-control";
        element.appendChild(leyend);
      }
      super({
        element: element,
        target: options.target,
      });
    }
  }

  useEffect(() => {
    drawSource.clear();
    if (map) {
      // layerHasChanged = true;

      let center = map.getView().getCenter();
      let zoom = map.getView().getZoom();
      // oldZoom = zoom;
      // oldCenter = center;
      setLayerHasChanged(true);
      setOldCenter(center);
      setOldZoom(zoom);
      //console.log("Estableciendo antigua posición",oldZoom, oldCenter )
    }
    if (isDrawEnabledOnStartUp) {
    }
    setDrawEnabled(isDrawEnabledOnStartUp);
  }, [isDrawEnabledOnStartUp]);

  class ClearDrawGeometryControl extends Control {
    constructor() {
      let options = {};

      let buttonNotify = document.createElement("div");
      buttonNotify.innerHTML = renderToString(
        <div
          className="clearDrawButton"
          style={{
            color: "white",
            width: "100%",
            paddingBottom: "0.2rem",
            maxHeight: "40px",
          }}
          title="Borrar geometría"
        >
          Clic aquí para deshacer o borrar
        </div>
      );

      // let buttonNotify = document.createElement('div');
      // buttonNotify.innerHTML = renderToString(<div className="clearDrawButton" style={{color:"white", width:"100%",paddingBottom:"0.2rem", maxHeight:"40px"}} title="Borrar geometría">Haga clic aquí para borrar</div>);

      let elementNotify = document.createElement("div");
      elementNotify.className = "clear-draw-control ol-unselectable ol-control";
      elementNotify.appendChild(buttonNotify);

      super({
        element: elementNotify,
        target: options.target,
      });

      buttonNotify.addEventListener(
        "click",
        this.handleToogleDrawState.bind(this),
        false
      );
    }

    handleToogleDrawState() {
      try {
        drawSource.clear();
        draw.removeLastPoint();
      } catch (error) {
        console.warning("Problema borrando el punto", error);
      }
    }
  }

  class MessageGetCoordinatesGeometryControl extends Control {
    constructor() {
      let options = {};

      let buttonNotify = document.createElement("div");
      buttonNotify.innerHTML = renderToString(
        <div
          className="exitDrawButton"
          style={{ color: "white", width: "100%", maxHeight: "40px" }}
          title="Marcar una localización"
        >
          Click en el mapa para marcar una localización
        </div>
      );
      let elementNotify = document.createElement("div");
      elementNotify.className = "exit-draw-control ol-unselectable ol-control";
      elementNotify.appendChild(buttonNotify);

      super({
        element: elementNotify,
        target: options.target,
      });

      //buttonNotify.addEventListener('click', this.handleToogleDrawState.bind(this), false);
    }
  }
  class ExitDrawGeometryControl extends Control {
    constructor() {
      let options = {};

      let buttonNotify = document.createElement("div");
      buttonNotify.innerHTML = renderToString(
        <div
          className="exitDrawButton"
          style={{ color: "white", width: "100%", maxHeight: "40px" }}
          title="Dibujar geometría"
        >
          Click aquí para salir del modo dibujo
        </div>
      );
      let elementNotify = document.createElement("div");
      elementNotify.className = "exit-draw-control ol-unselectable ol-control";
      elementNotify.appendChild(buttonNotify);

      super({
        element: elementNotify,
        target: options.target,
      });

      buttonNotify.addEventListener(
        "click",
        this.handleToogleDrawState.bind(this),
        false
      );
    }

    handleToogleDrawState() {
      drawSource.clear();
      setShowUI(drawEnabled);
      if (drawEnabled) {
        var evt = new CustomEvent("drawGeometry", {
          detail: { geometry: null },
        });
        document.dispatchEvent(evt);
      }

      if (map) {
        // layerHasChanged = true;

        let center = map.getView().getCenter();
        let zoom = map.getView().getZoom();
        oldZoom = zoom;
        oldCenter = center;
        setLayerHasChanged(true);
        setOldCenter(center);
        setOldZoom(zoom);

        //console.log("Estableciendo antigua posición",oldZoom, oldCenter )
      }

      setDrawEnabled(!drawEnabled);
    }
  }
  class ToggleDrawGeometryControl extends Control {
    constructor() {
      let options = {};

      let button = document.createElement("div");
      button.innerHTML = renderToString(
        <button
          style={{ width: "20px", maxHeight: "20px" }}
          title="Dibujar geometría"
        >
          <DrawIcon />
        </button>
      );
      let element = document.createElement("div");
      if (isGeolocationEnabled)
        element.className =
          "full-draw-control-next-row ol-unselectable ol-control";
      if (!isGeolocationEnabled)
        element.className = "full-draw-control ol-unselectable ol-control";
      element.appendChild(button);

      // let buttonNotify = document.createElement('div');
      // buttonNotify.innerHTML = renderToString(<Button style={{width:"50vw",position:"absolute", bottom:"10px", left:"50%", transform:"translateX(50%)", maxHeight:"40px"}} title="Dibujar geometría">Click aquí para salir del modo dibujo</Button>);
      // let elementNotify = document.createElement('div');
      // elementNotify.className = "ol-unselectable ol-control"
      // elementNotify.appendChild(buttonNotify)

      super({
        element: element,
        target: options.target,
      });

      button.addEventListener(
        "click",
        this.handleToogleDrawState.bind(this),
        false
      );
    }

    handleToogleDrawState() {
      drawSource.clear();
      setShowUI(drawEnabled);
      if (drawEnabled) {
        var evt = new CustomEvent("drawGeometry", {
          detail: { geometry: null },
        });
        document.dispatchEvent(evt);
      }

      if (map) {
        // layerHasChanged = true;

        let center = map.getView().getCenter();
        let zoom = map.getView().getZoom();
        // oldZoom = zoom;
        // oldCenter = center;
        setLayerHasChanged(true);
        setOldCenter(center);
        setOldZoom(zoom);
        //console.log("Estableciendo antigua posición",oldZoom, oldCenter )
      }

      setDrawEnabled(!drawEnabled);
    }
  }
  class CenterMapControl extends Control {
    constructor() {
      let options = {};

      let button = document.createElement("div");

      button.innerHTML = renderToString(
        <button
          style={{ width: "20px", maxHeight: "20px" }}
          title="Centrar mapa"
        >
          <CenterFocusStrongIcon />
        </button>
      );

      let element = document.createElement("div");
      element.className = "full-center-map ol-unselectable ol-control";
      element.appendChild(button);

      super({
        element: element,
        target: options.target,
      });

      button.addEventListener("click", this.handleCenterMap.bind(this), false);
    }

    handleCenterMap() {
      const view = map.getView();
      // Aquí es donde centras el mapa.
      console.log("Combined extenr", combinedExtent, combinedCenter);
      if (combinedExtent != false && combinedExtent != "ine") {
        view.fit(combinedExtent, {
          padding: [150, 100, 150, 100],
          duration: 250,
          maxZoom: 18,
        });
      } else {
        view.animate({
          center: combinedCenter,
          duration: 250, // Duración de la animación en milisegundos, ajusta según necesites
        });
      }
    }
  }
  class CenterOnCurrentLocationMapControl extends Control {
    constructor() {
      let options = {};

      let button = document.createElement("div");

      button.innerHTML = renderToString(
        <button
          style={{ width: "20px", maxHeight: "20px" }}
          title="Centrar mapa en la posición actual"
        >
          <PlaceIcon />
        </button>
      );

      let element = document.createElement("div");
      element.className =
        "full-center-map-on-current-location ol-unselectable ol-control";
      element.appendChild(button);

      super({
        element: element,
        target: options.target,
      });

      button.addEventListener("click", this.handleCenterMap.bind(this), false);
    }

    handleCenterMap() {
      const view = map.getView();
      // Aquí es donde centras el mapa.
      console.log("geoPositionPoint", geoPositionPoint);
      if (geoPositionPoint && geoPositionPoint != undefined) {
        view.animate({
          center: geoPositionPoint,
          zoom: 19,
          duration: 250, // Duración de la animación en milisegundos, ajusta según necesites
        });
      } else {
        console.log("Esperando evento de geoposición...");
        geolocation.once("change:position", async function () {
          let coordinates = geolocation.getPosition();
          console.log("Coordinates", coordinates);
          setGeoPositionPoint(coordinates);

          map.getView().animate({
            center: coordinates,
            zoom: 17,
            duration: 350, // Duración de la animación en milisegundos, ajusta según necesites
          });
        });
        // const position = geolocation.getPosition()
        // console.log("position", position)
        // if(position){
        //   view.animate({
        //     center:position,
        //     zoom : 19,
        //     duration: 250 // Duración de la animación en milisegundos, ajusta según necesites
        //   })
        // }else{
        //   Notiflix.Notify.warning("Error obteniendo la ubicación")

        // }
      }
    }
  }
  class LayerSelectorControl extends Control {
    constructor() {
      let options = {};

      let selector = document.createElement("select");
      selector.title = "Selector de mapa base";
      let selector_html = "<select>"; //+"</select>"
      for (let layer_key in layers_data) {
        selector_html += `<option ${
          selectedLayer === layer_key ? 'selected="selected"' : ""
        } value="${layer_key}">${layers_data[layer_key].label} </option>`;
      }
      selector_html += "</select>";
      selector.innerHTML = selector_html;
      selector.className = "layer-changer-selector";
      let element = document.createElement("div");
      if (!isAdressSearchBarEnabled)
        element.className = "layer-changer-map ol-unselectable ol-control";
      if (isAdressSearchBarEnabled)
        element.className = "layer-changer-map-2row ol-unselectable ol-control";
      element.appendChild(selector);
      super({
        element: element,
        target: options.target,
      });

      selector.addEventListener(
        "change",
        this.handleChangeLayer.bind(this),
        false
      );
    }

    handleChangeLayer(e) {
      const selectedValue = e.target.value;

      if (map) {
        // layerHasChanged = true;
        let center = map.getView().getCenter();
        let zoom = map.getView().getZoom();
        // oldZoom = zoom;
        // oldCenter = center;
        setLayerHasChanged(true);
        setOldCenter(center);
        setOldZoom(zoom);
        //console.log("Estableciendo antigua posición",oldZoom, oldCenter )
      }
      setSelectedLayer(selectedValue);
      //initProjections();
    }
  }
  class BaseMapSelectorControl extends Control {
    constructor() {
      let options = {};

      let div = document.createElement("div");

      const next_index = (base_maps_index + 1) % BASE_MAPS_AVIABLES.length;

      const img_folder = assetsBasePath
        ? assetsBasePath
        : process.env.PUBLIC_URL + "/images/";
      const current_img = BASE_MAPS_IMAGES_PATHS[next_index];
      //console.log("ChangeBasemap defaultBaseMap", defaultBaseMap, "equal to ", defaultBaseMap ==satellite_value)
      div.innerHTML = renderToString(
        <img
          id="BaseMapSelectorControl"
          value={BASE_MAPS_AVIABLES[next_index]}
          src={img_folder + current_img}
        />
      );
      //div.value = defaultBaseMap;
      //div.setAttribute("value",street_value );
      // div.title = "Vista callejero";

      // if(defaultBaseMap == satellite_value){
      //   //div.innerHTML ='<img src="'+img_folder+img_street+'" />';

      //   div.setAttribute("value",satellite_value );

      // }else{
      //   //div.innerHTML ='<img src="'+img_folder+img_satellite+'" />';
      //   div.innerHTML =renderToString(<img src={img_folder+img_satellite} />);

      //   div.setAttribute("value",street_value );
      // }

      div.className = "basemap-changer-selector-image";
      let element = document.createElement("div");

      element.className = "full-basemap-changer-map ol-unselectable ol-control";
      element.appendChild(div);
      super({
        element: element,
        target: options.target,
      });

      div.addEventListener("click", this.handleChangeBasemap.bind(this), false);
    }

    handleChangeBasemap(e, data) {
      console.log("Change basemap", base_maps_index);
      base_maps_index = (base_maps_index + 1) % BASE_MAPS_AVIABLES.length;
      console.log("Change basemap updated", base_maps_index);
      const next_index = (base_maps_index + 1) % BASE_MAPS_AVIABLES.length;
      console.log("Change basemap updated next", next_index);

      const img_folder = assetsBasePath
        ? assetsBasePath
        : process.env.PUBLIC_URL + "/images/";
      const current_img = BASE_MAPS_IMAGES_PATHS[next_index];
      console.log("Change basemap img", img_folder, current_img);

      //console.log("ChangeBasemap defaultBaseMap", defaultBaseMap, "equal to ", defaultBaseMap ==satellite_value)
      const old_element = document.getElementById("BaseMapSelectorControl");
      const parent = old_element.parentElement;
      const grand_parent = parent.parentElement;
      parent.parentNode.removeChild(parent);

      //parent.className =  'full-basemap-changer-map ol-unselectable ol-control';
      let new_div = document.createElement("div");
      new_div.innerHTML = renderToString(
        <img
          id="BaseMapSelectorControl"
          value={BASE_MAPS_AVIABLES[next_index]}
          src={img_folder + current_img}
        />
      );
      grand_parent.appendChild(new_div);
      new_div.className = "basemap-changer-selector-image";
      new_div.addEventListener(
        "click",
        this.handleChangeBasemap.bind(this),
        false
      );

      let sourcesBaseMaps = getSourceBaseMapFromName(
        BASE_MAPS_AVIABLES[base_maps_index]
      );
      const layers = [];
      for (let source of sourcesBaseMaps) {
        layers.push(
          new TileLayer({
            source: source,
          })
        );
      }
      map.getLayers().setAt(
        0,
        new LayerGroup({
          layers: layers,
        })
      );
      initProjections();
    }
  }
  class FullScreenButtonControl extends Control {
    constructor() {
      let options = {};
      let button = document.createElement("div");
      let enterFullScreenButton = renderToString(
        <button
          style={{ maxWidth: "20px", maxHeight: "20px" }}
          title="Activar pantalla completa"
        >
          <FullscreenSharpIcon />
        </button>
      );
      let exitFullScreenButton = renderToString(
        <button
          style={{ maxWidth: "20px", maxHeight: "20px" }}
          title="Desactivar pantalla completa"
        >
          <FullscreenExitIcon />
        </button>
      );
      button.innerHTML = isFullScreen
        ? exitFullScreenButton
        : enterFullScreenButton;
      let element = document.createElement("div");
      element.className =
        "full-full-screen-map-button ol-unselectable ol-control";
      element.appendChild(button);

      super({
        element: element,
        target: options.target,
      });

      button.addEventListener("click", this.toggleFullScreen.bind(this), false);
    }

    enableFullscreen = () => {
      const mapElement = mapContainerRef.current;
      if (mapElement.requestFullscreen) {
        mapElement.requestFullscreen();
      } else if (mapElement.mozRequestFullScreen) {
        mapElement.mozRequestFullScreen();
      } else if (mapElement.webkitRequestFullscreen) {
        mapElement.webkitRequestFullscreen();
      } else if (mapElement.msRequestFullscreen) {
        mapElement.msRequestFullscreen();
      }
    };
    toggleFullScreen = () => {
      const mapElement = mapContainerRef.current;
      // console.log("Toggle full screen", isFullScreen);
      try {
        if (!isFullScreen) {
          setIsFullScreen(true);
          if (mapElement.requestFullscreen) {
            mapElement.requestFullscreen();
          } else if (mapElement.mozRequestFullScreen) {
            mapElement.mozRequestFullScreen();
          } else if (mapElement.webkitRequestFullscreen) {
            mapElement.webkitRequestFullscreen();
          } else if (mapElement.msRequestFullscreen) {
            mapElement.msRequestFullscreen();
          }
        } else {
          setIsFullScreen(false);
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
          }
        }
      } catch (error) {
        console.error("Error en la pantalla completa", error);
      }
    };
  }
  const checkFullScreen = () => {
    const isCurrentlyFullScreen = document.fullscreenElement !== null;
    if (isFullScreen !== isCurrentlyFullScreen) {
      setIsFullScreen(isCurrentlyFullScreen);
    }
  };

  const calculeCenterOfMunicipeFromIne = (ine) => {
    try {
      if (ine.length < 5) {
        ine = ine.padStart(5, "0");
      }
      return regionsLimits[ine]["features"][0]["geometry"]["coordinates"];
    } catch (error) {
      console.log("Ine not found");
    }
    return DEFAULT_CENTER;
  };

  useEffect(() => {
    // Registro de la proyección EPSG:25830 (Si no está ya definido en OpenLayers)
    // console.log("Inicializando mapa y creando proyecciones...");
    initProjections();
  }, []);
  useEffect(() => {
    // console.log("Nueva geometria", geojsons);
    setOldCenter(false);
  }, [geojsons]);
  useEffect(() => {
    document.addEventListener("fullscreenchange", checkFullScreen);
    try {
      if (mapState) {
        if (fullScreenButtonRef.current) {
          //fullScreenButtonRef.current.remove();
          // map.removeControl(fullScreenButtonRef.current);
          // fullScreenButtonRef.current = null;
          mapState.getControls().forEach(function (control) {
            if (control instanceof FullScreenButtonControl) {
              mapState.removeControl(control);
            }
          }, this);
        }
        const fullScreenButton = new FullScreenButtonControl();
        mapState.addControl(fullScreenButton);
        fullScreenButtonRef.current = fullScreenButton;
      }
    } catch (error) {
      console.warn("Error con el controlador de pantalla completa.", error);
    }

    return () => {
      document.removeEventListener("fullscreenchange", checkFullScreen);
    };
  }, [isFullScreen, mapState]);

  useEffect(() => {
    // console.log("Actualizando geometría...", geojsons.substring(0, 25));
    // Creación de la capa de mapa base usando OSM (Open Street Map)
    // Creación de la capa vectorial usando los datos GeoJSON, si se proporcionan
    set_default_layers();
    let polygonVectorLayers = false;
    let pointVectorLayers = false;
    combinedCenter = DEFAULT_CENTER;
    combinedExtent = false;
    console.log("Enviando mapRefreshStart");
    var evt = new CustomEvent("mapRefreshStart", {});
    document.dispatchEvent(evt);
    if (mapState) {
      preloadCenter = mapState.getView().getCenter();
      preloadZoom = mapState.getView().getZoom();
      console.log(
        "Comprobando y actualizando centros",
        preloadCenter,
        preloadZoom
      );
    }
    if (
      geojsons == null ||
      geojsons == "" ||
      geojsons == undefined ||
      geojsons == false ||
      geojsons == "[]"
    ) {
      geojsons = "[]";

      combinedCenter = calculeCenterOfMunicipeFromIne(ineCode);
      combinedExtent = "ine";
    } else {
      console.log(
        "Actualizando have_geometry_not_empty_once",
        have_geometry_not_empty_once
      );
      set_have_geometry_not_empty_once(have_geometry_not_empty_once + 1);
      let allPolygons;
      let allPoints;
      let vectorSources;
      let markerSources;

      // Crear una capa vectorial para cada VectorSource
      if (selectedLayer == "transparent") {
        ({ allPolygons, allPoints, vectorSources, markerSources } =
          readGeoJsons("[]", DEFAULT_PROJECTION));
        combinedCenter = oldCenter;
      } else {
        ({ allPolygons, allPoints, vectorSources, markerSources } =
          readGeoJsons(geojsons, DEFAULT_PROJECTION));
        //console.log("All polygons", allPolygons, vectorSources);
        //console.log("All points", allPoints, markerSources);

        polygonVectorLayers = layers_data[
          selectedLayer
        ].getVectorLayerFromSources(
          polygonVectorLayers,
          vectorSources,
          allPolygons,
          strokeColor,
          strokeWidth,
          fillColor,
          fillPolygonsOpacity,
          mapState,
          getBgColorFromFeature
        );
        pointVectorLayers = layers_data[
          selectedLayer
        ].getVectorLayerFromSources(
          pointVectorLayers,
          markerSources,
          allPoints,
          strokeColor,
          strokeWidth,
          fillColor,
          fillPointsOpacity,
          mapState,
          getBgColorFromFeature
        );
        ({ combinedExtent, combinedCenter } = calculeCenterOfFeatures(
          combinedExtent,
          allPolygons,
          allPoints
        ));
      }

      // Calcula el centro combinado
    }

    // Crear el mapa con todas las capas vectoriales

    //console.log("combinedCenter", combinedCenter);
    if (mapRef.current) {
      mapRef.current.innerHTML = "";
    }
    overlay = overlayInit(popupRef);
    const tooltip = tooltipInit(tooltipRef);
    if (isDrawEnabled) {
      drawSource = new VectorSource({ wrapX: false });
    }
    map = mapInit(
      mapRef,
      tooltip,
      overlay,
      baseLayer,
      polygonVectorLayers,
      pointVectorLayers,
      combinedCenter,
      DEFAULT_PROJECTION,
      scaleLineControl,
      drawEnabled,
      drawSource,
      oldZoom,
      oldCenter,
      isGeolocationEnabled
      //combinedExtent=="ine"?17:false
    );
    setMapState(map);
    if (isGeolocationEnabled && mustConfigureGeolocation) {
      // if(!geolocation.getProjection()){
      //   geolocation.setProjection(map.getView().getProjection());
      // }
      geolocationVectorLayer = addGeolocation(
        map,
        geolocation,
        geoPositionPoint,
        setGeoPositionPoint,
        geoAccuraccyGeometry,
        setGeoAccuraccyGeometry
      );
    }
    if (isGeolocationEnabled && !mustConfigureGeolocation) {
      geolocationVectorLayer.setMap(map);
    }

    if (selectedLayer != "transparent" && !drawEnabled) {
      map.on("moveend", () =>
        layers_data[selectedLayer].updateLayerStyle(
          map,
          polygonVectorLayers,
          strokeColor,
          strokeWidth,
          fillColor,
          fillPolygonsOpacity,
          getBgColorFromFeature
        )
      );
      map.on("moveend", () =>
        layers_data[selectedLayer].updateLayerStyle(
          map,
          pointVectorLayers,
          strokeColor,
          strokeWidth,
          fillColor,
          fillPolygonsOpacity,
          getBgColorFromFeature
        )
      );
    }

    // ADD CONTROLS
    if (showUI) {
      var centerControl = new CenterMapControl();
      map.addControl(centerControl);
      const mapSelector = new BaseMapSelectorControl();
      map.addControl(mapSelector);
      const fullScreenButton = new FullScreenButtonControl();
      map.addControl(fullScreenButton);
    }

    if (showUI) map.addControl(new LeyendControl());
    if (isGeolocationEnabled) {
      const centerLocationControl = new CenterOnCurrentLocationMapControl();
      map.addControl(centerLocationControl);
    }
    if (isAdressSearchBarEnabled && showUI) {
      const SearchAddressControl = new Control({
        element: SearchAddressDiv,
      });
      map.addControl(SearchAddressControl);
    }
    if (isDrawEnabled) {
      map.addControl(new ToggleDrawGeometryControl());
      if (drawEnabled) {
        map.addControl(new ExitDrawGeometryControl());
      }
    }
    if (isDrawEnabledOnStartUp && !isDrawEnabled) {
      map.addControl(new ClearDrawGeometryControl());
    }
    if (
      isGetCoordinatesOnClickEnabled &&
      !isDrawEnabledOnStartUp &&
      !isDrawEnabled
    ) {
      map.addControl(new MessageGetCoordinatesGeometryControl());
    }

    if (Object.keys(layers_data).length > 1 && showUI) {
      const layerSelector = new LayerSelectorControl();
      map.addControl(layerSelector);
    }
    // let geopositionCoords = null;
    // if(isGeolocationEnabled){
    //   console.log("Obteniendo centro de la localización")
    //   let geopositionCoords = geolocation.getPosition()
    //   console.log("geopositionCoords ", geopositionCoords)
    //   map.getView().setCenter(geopositionCoords)
    //   map.getView().setZoom(17)
    // }
    if (
      (polygonVectorLayers || pointVectorLayers) &&
      combinedExtent &&
      selectedLayer != "transparent"
    ) {
      if (!layerHasChanged) {
        if (geoPositionPoint && isGeolocationEnabled) {
          map.getView().animate({
            center: geoPositionPoint,
            zoom: 19,
            duration: 250,
          });
        } else {
          map.getView().fit(combinedExtent, {
            padding: [150, 100, 150, 100],
          });
          if (map.getView().getZoom() > 20) {
            map.getView().setZoom(17);
          }
        }
      }
      if (!drawEnabled) {
        map.on("pointermove", function (evt) {
          layers_data[selectedLayer].handleMapPointerMove(
            map,
            evt,
            layers_data[selectedLayer],
            tooltip,
            tooltipRef,
            strokeColor,
            strokeHoverColor,
            strokeWidth,
            fillColor,
            fillPolygonsOpacity,
            fillPointsOpacity,
            getBgColorFromFeature,
            isGetCoordinatesOnClickEnabled
          );
        });
      }
    } else {
      if (
        !layerHasChanged &&
        selectedLayer != "transparent" &&
        combinedExtent != "ine"
      ) {
        map.getView().setZoom(5);
      } else if (
        !layerHasChanged &&
        selectedLayer != "transparent" &&
        combinedExtent == "ine"
      ) {
        map.getView().setZoom(12);
      }
    }
    map.on("singleclick", async function (evt) {
      handleMapClick(
        map,
        evt,
        overlay,
        setModalBody,
        setIsModalOpen,
        layers_data[selectedLayer],
        mustShowOnClickFeature,
        isGetCoordinatesOnClickEnabled,
        pointMarker,
        assetsBasePath,
        drawEnabled
      );
    });
    //console.log("Comprobando antigua posición", layerHasChanged, oldCenter, oldZoom)
    console.log(
      "Comprobando si centrar geometría",
      have_geometry_not_empty_once,
      have_geometry_not_empty_once > 0,
      !center_on_new_polygons,
      !(oldCenter && oldZoom),
      preloadCenter,
      preloadZoom,
      oldCenter && oldZoom
    );

    if (
      have_geometry_not_empty_once > 0 &&
      !center_on_new_polygons &&
      !(oldCenter && oldZoom) &&
      preloadCenter &&
      preloadZoom
    ) {
      map.getView().setCenter(preloadCenter);
      map.getView().setZoom(preloadZoom);
    }
    if (oldCenter && oldZoom) {
      //console.log("Recuperando antigua posición", oldCenter, oldZoom);
      map.getView().setCenter(oldCenter);
      map.getView().setZoom(oldZoom);
      // map.getView().animate({
      //   center: oldCenter,
      //   zoom: oldZoom,
      //   duration: 1050 // Duración de la animación en milisegundos, ajusta según necesites
      // });
    }

    setLayerHasChanged(false);
    if (drawEnabled) {
      draw = new Draw({
        source: drawSource,
        type: drawType,
        style: new Style({
          image: new CircleStyle({
            radius: 5,
            stroke: new Stroke({
              color: "white",
              width: 2,
            }),
            fill: new Fill({
              color: "#0000ff", // Cambiar el color del cursor aquí
            }),
          }),
        }),
      });
      draw.on("drawend", function (evt) {
        console.log("Acabando dibujo");
        // var DrawnFeature = evt.feature;
        // var DrawnCoords = evt.feature.getGeometry().getCoordinates();
        var writer = new GeoJSON();
        const geojson = writer.writeFeatureObject(
          evt.feature instanceof RenderFeature
            ? toFeature(evt.feature)
            : evt.feature
        );
        //console.log("Drawn data drawend", geojson);

        mustClean = true;
        var evt2 = new CustomEvent("drawGeometry", {
          detail: { geometry: geojson },
        });
        document.dispatchEvent(evt2);

        var feature = evt.feature;

        // Cambiar el color de fondo del polígono
        feature.setStyle([
          new Style({
            fill: new Fill({
              color: "#33AA3377",
            }),
            stroke: new Stroke({
              // color: '#33ff33CC',
              color: "#0000ff",
              width: 3,
            }),
            zIndex: 1,
          }),
          // new Style({
          //   stroke: new Stroke({
          //     color: '#33ff33CC',
          //     width: 5
          //   }),
          //   zIndex: 0,
          // }),
        ]);
      });
      draw.on("drawstart", function (evt) {
        //console.log("Drawn data drawstart",mustClean);
        var feature = evt.feature;

        // const pointStyle = new Style({
        //   image: new CircleStyle({
        //     radius: 10,
        //     fill: new Fill({
        //       color: '#AAAAAA99' // Cambiar el color de los puntos aquí
        //     }),
        //     stroke: new Stroke({
        //       color: 'white',
        //       width: 3
        //     })
        //   })
        // })
        const polygonStyle = new Style({
          fill: new Fill({
            color: "#99999999",
          }),
          stroke: new Stroke({
            // color: '#33ff33CC',
            color: "#0000ff",
            width: 3,
            //lineJoin:"round"
          }),
          zIndex: 1,
        });
        feature.setStyle(polygonStyle);
        // Cambiar el color de fondo del polígono
        // feature.setStyle(function(feature) {
        //   var styles = [polygonStyle];
        //   feature.getGeometry().getCoordinates()[0].forEach(function(coord) {
        //     styles.push(pointStyle);
        //   });
        //   return styles;
        // });

        if (mustClean) {
          console.log("Limpiando dibujo");
          drawSource.clear();
          mustClean = false;
        }
        var writer = new GeoJSON();
        // let ft = evt.feature instanceof RenderFeature ? toFeature(evt.feature) : evt.feature;
        //console.log("draw point", ft, ft.getGeometry(), evt)
        //  var mouseCoordinatesStart=map.getEventCoordinate(evt);
        let coords = evt.target.finishCoordinate_;
        //         coords = coords.split(",");
        //console.log('you just started to draw at:', coords);
        let new_ft = new Point(coords);
        //console.log("draw new_ft",typeof(new_ft), new_ft)
        let geojson;
        try {
          geojson = writer.writeFeatureObject(
            new_ft instanceof RenderFeature ? toFeature(new_ft) : new_ft
          );
        } catch (err) {
          console.warn("Problema con geojson 1, intentando 2", err);
          geojson = writer.writeFeatureObject(
            evt.feature instanceof RenderFeature
              ? toFeature(evt.feature)
              : evt.feature
          );
          console.log("Exito");
        }
        if ("geometry" in geojson) {
          geojson["geometry"]["type"] = "Point";
        }
        console.log("DrawStart feature", geojson);
        var evt = new CustomEvent("drawGeometry", {
          detail: { geometry: geojson },
        });
        document.dispatchEvent(evt);
        //draw.setStyle(getPolygonWithPointsStyle(true))
      });
      draw.on("change", function (evt) {
        //  draw.setStyle(getPolygonWithPointsStyle());
      });
      draw.on("change:escKey", function (evt) {
        try {
          var geom = drawSource.getGeometry();
          var coords = geom.getCoordinates();
          var len = coords.length;
          if (len > 1) {
            var new_coordinates = coords.slice(0, len - 1);

            geom.setCoordinates(new_coordinates); //this is just visual
            //this (target.e) is the equivalent of this.sketchCoords_ in debug mode
            evt.target.e = new_coordinates;
          }
        } catch (error) {
          console.error("Error deshaciendo dibujado");
        }
      });

      map.addInteraction(draw);
    }
    console.log("Enviando mapRefreshEnd");
    var evt = new CustomEvent("mapRefreshEnd", {});
    document.dispatchEvent(evt);

    return () => map?.setTarget(undefined);
  }, [
    geojsons,
    selectedLayer,
    drawEnabled,
    oldCenter,
    oldZoom,
    isDrawEnabled,
    mustShowOnClickFeature,
    isGeolocationEnabled,
    isGetCoordinatesOnClickEnabled,
  ]);

  return (
    <div
      ref={mapContainerRef}
      style={{
        ...style,
        cursor:
          drawEnabled | isGetCoordinatesOnClickEnabled ? "crosshair" : "grab",
      }}
    >
      <div
        ref={mapRef}
        style={{ width: "100%", height: "100%", zIndex: "10" }}
      />

      <div ref={tooltipRef} className="tooltip" />
      {/* <div ref={popupRef} id="popup" class="ol-popup">
          <a href="#" id="popup-closer" class="ol-popup-closer"></a>
          <div ref={overlayRef}></div>
          
      </div> */}
      <GenericInfoMapModal
        buttonAction={() => {}}
        ModalButton={<></>}
        InitialAction={() => {}}
        Title={"Información"}
        BodyComponent={modalBody}
        ConfirmText={"OK"}
        CancelText={false}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        fullScreenContainerRef={mapContainerRef}
        isFullScreen={isFullScreen}
      />
    </div>
  );
};

export default OpenLayersMap;
